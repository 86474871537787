<template>
  <div :class="shape">
    <img class="uk-border-circle" v-if="src" :src="src">
      <Gravatar
          v-else-if="email"
          :email="email"
          :height="getSize"
          :width="getSize"
          default-img="identicon"
    />
  </div>
</template>


<script>
import Gravatar from 'vue-gravatar';

export default {
  name: 'UserGravatar',
  components: {
    Gravatar
  },
  computed: {
    getSize () {
      return parseInt(this.size)
    }
  },
  props: {
      size: { default: 40 },
      src: { type: String, default: '' },
      email: { type: String, default: '' },
      shape: { type: String, default: 'round-img-wrapper'}
  }
}
</script>

<style lang="scss">
.round-img-wrapper  {
  min-height: 40px;
  min-width: 40px;
  img {
    border-radius: 50%;
    object-fit: cover;
  }  
}
</style>

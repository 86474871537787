<template>
  <div>
    <div class="uk-inline uk-width-1-">

      <div class="uk-align-left uk-margin-remove-bottom">
        <h1 class="uk-margin-remove-bottom">{{ $t('Sidebar.Users') }}</h1>
      </div>

      <div class="uk-align-right uk-width-auto uk-margin-remove">
        <router-link to="users/new">
          <button class="uk-button uk-button-primary custom-button-padding uk-width-auto">
            <span class="uk-margin-small-right uk-padding-remove" uk-icon="plus-circle"></span> {{$t('Pages.Users.InviteUser')}}
          </button>
        </router-link>
      </div>

      <div class="uk-margin-small-right uk-margin-small-left uk-align-right uk-margin-remove-bottom">
        <div>
          <button :uk-tooltip="$t('Generic.Labels.Filter')" :aria-label="$t('Generic.Labels.Filter')" style="padding-left:10px; padding-right:10px; width:42px" class="uk-button uk-button-primary" type="button">
            <img src="@/assets/filter-icon.svg" :alt="$t('Generic.Labels.Filter')" uk-svg /> 
          </button>
          <div style="min-width: 110px;" uk-dropdown="mode: hover; pos: bottom-left; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 200">
            <ul class="uk-nav uk-dropdown-nav">
                <li class="uk-text-left uk-text-default" :class="filter === selectedFilter? 'uk-active uk-text-bold': '' " v-for="filter in selectFilters" :key="filter" :value="filter" > <a @click="selectFilter(filter)"> {{ $t(`Generic.Labels.${filter}`) }} </a> </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="uk-align-right uk-margin-remove-bottom ">
        <form class="uk-search uk-width-auto@m uk-search-default" @submit.prevent="">
            <span class="uk-search-icon" uk-search-icon></span>
            <a v-if="searchKey" class="uk-form-icon uk-form-icon-flip" uk-icon="icon: close" @click.prevent="clearSearch()"></a>
            <input @blur="updateSearchKeyInURL()" class="uk-search-input uk-width-medium" type="search" v-model.trim="searchKey" :placeholder="$t('Generic.Labels.Search')">
        </form>
      </div>

    </div>

    <hr />
    <div
      class="js-filter uk-grid-small uk-child-width-1-5@xl uk-child-width-1-4@l uk-child-width-1-3@m uk-child-width-1-2@s"
      uk-scrollspy="target: > div.users-list > .uk-card; cls: uk-animation-slide-top-medium; delay: 50; offset-top: 2000;"
      uk-grid
      v-if="filteredUsers && filteredUsers.length"
    >
      <div
        v-for="user in filteredUsers"
        :key="user.id"
        class="uk-grid-item-match users-list"
      >
        <UserCard :id="user.id" :name="`${user.firstName} ${user.lastName}`" :status="user.active" :email="user.email" :joined="user.registered" />
      </div>
    </div>
    <div v-if="fetching">
      <span uk-spinner></span>
    </div>
    <div v-else-if="(!filteredUsers.length && !fetching)">
      <span class="uk-text-meta" v-if="this.searchKey">{{$t('Pages.Users.NoUsers')}}</span>
      <span v-else>
        <span class="uk-text-meta">{{$t('Pages.Users.NoData')}}</span>
        <router-link to="users/new">
          <span class="uk-text-primary">{{$t('Pages.Users.InvitingUser')}}</span>
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UserCard from '@/components/cards/UserCard.vue';

export default {
  name: 'UsersView',
  components: {
    UserCard
  },
  data () {
    return {
      fetching: false,
      searchKey:  this.$route?.query?.search || '',
      selectFilters: ['All', 'Active', 'Inactive'],
      selectedFilter:  this.$route?.query?.filter || 'All',
      selectedSearchKey: '',
      orgId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      users: state => state.venom.org?.users || []
    }),
    filteredUsers() {
      return this.users.filter(user => {
        return (`${user.firstName} ${user.lastName}`.toLowerCase().includes(this.searchKey.toLowerCase())) && (this.selectedFilter === 'All' ?
         true : 
         this.selectedFilter === 'Active' ? user.active : user.active === false)
      })
    }
  },
  created() {
    this.fetchUsers()
  },
  mounted () {
    document.documentElement.scrollTop = 0
  },
  methods: {
    selectFilter (filter) {
      this.selectedFilter = filter
    },
    updateSearchKeyInURL () {
      const url = new URL(window.location);
      url.searchParams.set('search', this.searchKey);
      window.history.pushState({}, '', url);
    },
    async fetchUsers () {
      this.fetching = true
      await this.$store.dispatch('fetchUsers', { orgId: this.$route.params.id });
      this.fetching = false
    },
    clearSearch () {
      this.searchKey = '';
      const url = new URL(window.location);
      url.searchParams.delete('search');
      window.history.pushState({}, '', url);
    }
  },
  watch: {
      selectedFilter: {
          handler () {
            const url = new URL(window.location);
            url.searchParams.set('filter', this.selectedFilter);
            window.history.pushState({}, '', url);
          }
      }
  },
}
</script>

<style scoped>
.custom-button-padding {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
<template>
  <div class="uk-card uk-card-default uk-card-small uk-text-left uk-card-hover uk-border-rounded ">
    <router-link :to="{ name: 'OrgUserProfile', params: { id: orgId, userId: id } }" style="text-decoration: none">      
      <div class="uk-card-body uk-height-small uk-padding-remove-bottom">
        <div class="uk-grid-small">
              <div class="uk-flex uk-width-1-1" >
                <UserGravatar  class="uk-width-auto uk-margin-small-right "  size=60 :email="email"/>
                <div class=" uk-margin-small-left uk-width-expand vertical-center"> 
                        <h3 class="uk-card-title uk-margin-remove-top uk-margin-remove-bottom uk-text-left uk-margin-small-right uk-text-truncate">{{ getFirstName }} </h3>
                        <h3 class="uk-card-title uk-margin-remove-top uk-text-left uk-margin-small-right uk-text-truncate">{{ getLastName }}</h3>
                </div>
              </div>
              <div class="uk-width-expand">
                  <p class="uk-text-meta uk-margin-remove uk-text-truncate">{{$t('Pages.Users.Joined')}}: {{ $d( new Date(joined), 'long' ) }}</p>
              </div>
          </div>
        <p class="uk-text-meta uk-text-small uk-text-truncate uk-margin-remove">{{ email }}</p>
      </div>
    </router-link>
    <div class="uk-card-footer">
      <div class="uk-text-meta uk-float-right" v-if="viewer.id !== id">
        <span v-if="removingUser" uk-spinner="ratio: 0.8" />
        <span v-else uk-icon="trash" class="uk-link" @click.prevent="removeUserFromOrg"></span>
      </div>
      <div class="uk-text-meta">
        <span v-if="status" class="uk-label uk-label-success">{{$t('Generic.Labels.Active')}}</span>
        <span v-else class="uk-label uk-label-danger">{{$t('Generic.Labels.Inactive')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Notification from '@/components/util/Notification';
import UserGravatar from '@/components/images/UserGravatar'
import { mapState } from 'vuex';

export default {
  name: 'UserCard',
  components: {
    UserGravatar
  },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    status: {
      type: Boolean,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    joined: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      orgId: this.$route.params.id,
      removingUser: false
    }
  },
  computed: {
    ...mapState({
      viewer: state => state.venom.org.viewer
    }),
    getFirstName () {
      const str = this.name.split(' ')[0];
      return str[0].toUpperCase() + str.slice(1)
    },
    getLastName () {
      const nameArr = this.name.split(' ')
      const str = nameArr && nameArr[1];
      return str ? str[0].toUpperCase() + str.slice(1) : ''
    }
  },
  methods: {
    async removeUserFromOrg()  {
      const store = this.$store;
      const userId = this.id; 
      const userName = this.name;
      const orgId = this.orgId;

      const UIkit = window.UIkit;
      UIkit.container = '.right-panel';
      UIkit.modal.confirm(this.$t('DeleteModal.ConfirmDelete', { name: userName }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then( async () => {
        this.removingUser = true;
        const ret = await store.dispatch('removeUserFromOrg', { orgId, userId });
        this.removingUser = false;
        if (ret) {
          Notification.showNotification(this.$t('Generic.Messages.Successful'), this.$t('DeleteModal.Removed') + ` ${userName}`);
        } else {
          Notification.showNotification(this.$t('Generic.Messages.Unsuccessful'), `${userName} ` + this.$t('DeleteModal.NotRemoved'), 'error');
        }
      },() => {
      });
    }
  }
}
</script>

<style lang="scss" scoped>
a.uk-card-body {
  text-decoration: none;
}
div.space-between {
  justify-content: space-between;
}
.vertical-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(+7%);
  transform: translateY(+7%);
}
.hover-no-text-decoration:hover {
    text-decoration: none;
}
</style>
